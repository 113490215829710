<template>
  <div>
    <app-header />

    <main v-if="! loading">
      <div
        class="
          uk-section uk-padding-remove-vertical uk-margin-remove-vertical
          in-equity-breadcrumb in-equity-breadcrumb-green
        "
      >
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-width-1-1">
              <ul class="uk-breadcrumb">
                <li>
                  <router-link :to="{ name: 'public.home' }">Home</router-link>
                </li>
                <li><span>Signals</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-section
          uk-section-primary
          uk-preserve-color
          uk-background-contain
          uk-background-center
          in-equity-15
        "
        :data-src="slider.background"
        data-uk-img=""
        :style="`background-image: url('${slider.background}')`"
      >
        <div class="uk-container uk-margin-top uk-margin-medium-bottom">
          <div class="uk-grid">
            <div class="uk-width-3-5@m">
              <h1 class="uk-margin-small-bottom">
                {{ slider.title }}
              </h1>
              <p
                class="uk-text-lead uk-margin-remove-top uk-text-bold"
                style="color: #fff"
              >
                {{ slider.tagline }}
              </p>
              <div
                class="
                  uk-grid-large
                  uk-child-width-1-2@m
                  uk-child-width-1-2@s
                  uk-margin-medium-top
                  uk-margin-medium-bottom
                  uk-grid
                "
                data-uk-grid=""
              >
                <div class="uk-first-column" v-for="(item, i) in slider.features" :key="`slider-item-${i}`">
                  <div class="uk-flex uk-flex-middle">
                    <div class="uk-margin-right">
                      <img
                        :src="item.icon"
                        :data-src="item.icon"
                        alt="icon-1"
                        width="96"
                        data-height=""
                        data-uk-img=""
                      />
                    </div>
                    <div>
                      <p class="uk-margin-remove uk-text-emphasis">
                        {{ item.title }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div class="uk-flex uk-flex-left uk-flex-middle get-started">
                <h5 class="uk-margin-remove">Ready to get started?</h5>
                <router-link
                  :to="{ name: 'register' }"
                  class="
                    uk-button uk-button-primary uk-border-rounded uk-margin-left
                  "
                  >Sign Up<i
                    class="fas fa-arrow-circle-right uk-margin-small-left"
                  ></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="uk-section in-equity-3 in-offset-top-20">
        <div class="uk-container uk-margin-large-bottom">
          <div class="uk-grid uk-flex uk-flex-middle">
            <div class="uk-width-expand@m">
              <h1 class="uk-margin-small-bottom">
                {{ signals.title }}
              </h1>
              <ul class="uk-list in-list-check">
                <li v-for="(item, i) in signals.items" :key="`signals-${i}`">{{ item }}</li>
              </ul>
              <router-link
                :to="{ name: 'register' }"
                class="uk-button uk-button-primary uk-button-large"
                >Sign Up<i
                  class="fas fa-arrow-circle-right uk-margin-small-left"
                ></i
              ></router-link>
            </div>
            <div
              class="uk-width-2xlarge uk-flex uk-flex-right uk-flex-center@s"
            >
              <div
                class="
                  uk-card uk-card-body uk-card-default uk-border-rounded
                  in-margin-top-60@s
                "
              >
                <div id="tradingview-widget">
                  <div style="width: 100%">
                    <table class="pricing-table">
                      <tbody>
                        <tr>
                          <td
                            style="
                              border-bottom: solid 1px #ddd;
                              border-right: solid 1px #ddd;
                            "
                          >
                            <h3>1 Month</h3>
                            <h1>
                              $ {{ membership_plans[1].whole }}
                              <small>.{{ membership_plans[1].cent }}</small>
                            </h1>
                          </td>
                          <td style="border-bottom: solid 1px #ddd">
                            <h3>3 Months</h3>
                            <h1>
                              $ {{ membership_plans[3].whole }}
                              <small>.{{ membership_plans[3].cent }}</small>
                            </h1>
                          </td>
                        </tr>
                        <tr>
                          <td style="border-right: solid 1px #ddd">
                            <h3>6 Months</h3>
                            <h1>
                              $ {{ membership_plans[6].whole }}
                              <small>.{{ membership_plans[6].cent }}</small>
                            </h1>
                          </td>
                          <td>
                            <h3>12 Months</h3>
                            <h1>
                              $ {{ membership_plans[12].whole }}
                              <small>.{{ membership_plans[12].cent }}</small>
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          uk-section uk-section-primary uk-preserve-color
          in-equity-6
          uk-background-contain uk-background-center
        "
        data-src="/new/in-equity-decor-2.svg"
        data-uk-img=""
        style="background-image: url('/new/in-equity-decor-2.svg')"
      >
        <div class="uk-container uk-margin-small-bottom">
          <div class="uk-grid uk-flex uk-flex-center">
            <div class="uk-width-3-4@m uk-margin-top">
              <div
                class="
                  uk-grid
                  uk-child-width-1-3@s
                  uk-child-width-1-3@m
                  uk-text-center
                  in-register
                "
              >
                <div class="uk-width-1-1 in-h-line uk-first-column">
                  <h2>{{ getting_started.title }}</h2>
                </div>
                <div class="uk-grid-margin uk-first-column" v-for="(item, i) in getting_started.items"
                  :key="`getting-starteditem-${i}`">
                  <span class="in-icon-wrap circle">{{ i + 1 }}</span>
                  <p>{{ item }}</p>
                </div>
                <div class="uk-width-1-1 uk-grid-margin uk-first-column">
                  <router-link
                    :to="{ name: 'register' }"
                    class="uk-button uk-button-text"
                    >Open account<i
                      class="fas fa-arrow-circle-right uk-margin-small-left"
                    ></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <loading v-if="loading" />

    <app-footer :disclaimer="disclaimer" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      membership_plans: { 1: {}, 3: {}, 6: {}, 12: {} },
      loading: true,
      signals: {},
      getting_started: {},
      disclaimer: "",
      slider: {}
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/signals-pricing").then((response) => {
        this.membership_plans = response.data.membership_plans;
      });

      this.loading = true;

      this.$axios.get("/api/v1/sitecontent/public-signals").then((response) => {
        this.slider = response.data.slider;
        this.signals = response.data.signals;
        this.getting_started = response.data.getting_started;
        this.disclaimer = response.data.disclaimer;
        this.loading = false;
      });
    },
  },
};
</script>
